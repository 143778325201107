import { useEffect, useMemo, useRef, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";
import PagePermissionWarning from "../../containers/AdminLayout/PagePermissionWarning/PagePermissionWarning";
import { useOrg } from "../../contexts/OrgContext";

import privateCellularClient from "@/apis/privateCellularClient";
import $ from "jquery";
import { Button } from "react-bootstrap";
import IcomoonIcon from "../../components/Icons/IcomoonIcon";
import SntPanel from "../../components/Panel/SntPanel";
import SntLink from "../../components/ReactBootstrap/SntLink";
import SntDialog from "../../components/SntDialog/SntDialog";
import SntInfoPanel from "../../components/SntInfo/SntInfoPanel";
import SntWarning from "../../components/SntInfo/SntWarning";
import AdminLayout from "../../containers/AdminLayout/AdminLayout";
import StringUtils from "../../utils/StringUtils";
import PageHeader from "@/containers/PageHeader/PageHeader";
import { SntAdvancedSearchTableViewCard } from "@/components/SntTableViewCard";

const pageName = "PRIVATE_CELLLAR";
const SHARED_KEY = "PRIVATE_CELLLAR";

const PrivateCellular = () => {
  const { orgId } = useOrg();
  const language = useSelector((state) => state.language);
  const loginInfo = useSelector((state) => state.user);
  let { permissionsMap, features, organisation } = loginInfo;

  const fileRef = useRef();
  const [uploadError, setUploadError] = useState("");

  const [dialogMessage, setDialogMessage] = useState(language.error_key);
  const [isShowDialogMessage, setShowDialogMessage] = useState(false);
  const [search, setSearch] = useState("");
  const [reload, setReload] = useState(false);

  const onUploadClicked = () => {
    $(fileRef.current).click();
  };

  useEffect(() => {
    $(fileRef.current).change(function () {
      let render = new FileReader(),
        input = this;

      render.onload = function (e) {
        let form = $(fileRef.current).closest("form");
        let dataForm = new FormData(form[0]);

        privateCellularClient
          .uploadPrivateCellularFile(
            orgId || loginInfo.organisation.id,
            dataForm
          )
          .then(({ data }) => {
            setUploadError(null);
            setDialogMessage(
              StringUtils.replaceStr(language.v43_successful_key, data)
            );
            setShowDialogMessage(true);
            setReload((pre) => !pre);
          })
          .catch(({ response }) => {
            setDialogMessage(
              response?.data?.message || language.update_fail_keyupdate_fail_key
            );
            setShowDialogMessage(true);
            setUploadError(response.data.message);
          });
      };
      render.readAsDataURL(input.files[0]);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allColumns = useMemo(() => {
    const allColumns = [
      {
        key: "mcc",
        description: "MCC",
        title: "MCC",
        Cell: ({ cell, value }) => {
          return value ? value : "";
        },
      },
      {
        key: "net",
        description: "MNC",
        title: "MNC",
        Cell: ({ cell, value }) => {
          return value ? value : "";
        },
      },
      {
        key: "cell",
        description: "Cell ID",
        title: "Cell ID",
        Cell: ({ cell, value }) => {
          return value ? value : "";
        },
      },
      {
        key: "area",
        description: "LAC",
        title: "LAC",
        Cell: ({ cell, value }) => {
          return value ? value : "";
        },
      },
      {
        key: "actualLat",
        title: language.location_key,
        description: language.location_key,
        style: {
          minWidth: 200,
        },
        Cell: ({ cell, value }) => {
          let full = cell.row.original;
          return full.actualLat && full.actualLng ? (
            <>
              <div className="d-flex">
                latitude: {full.actualLat} &nbsp;
                <a
                  target=" "
                  href={
                    "http://maps.google.com/maps?q=loc:" +
                    full.actualLat +
                    "," +
                    full.actualLng
                  }
                >
                  <IcomoonIcon
                    icon="map2"
                    size="12"
                    color="#6EDC85"
                  ></IcomoonIcon>
                </a>
              </div>
              <div>longitude: {full.actualLng}</div>
            </>
          ) : (
            ""
          );
        },
      },
    ];

    return allColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultColumnInit = useMemo(
    () => ["mcc", "net", "cell", "area", "actualLat"],
    []
  );

  const table = useRef();

  useEffect(() => {
    // reload button
    table.current && table.current.refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  useEffect(() => {
    table.current && table.current.resetDefault();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const onChangeFilter = (data) => {
    if (data.search !== search) {
      setSearch(data.search);
    }
  };

  const onDataRequestChange = (data, callback) => {
    const { params } = data;
    if (table.current) {
      table.current.setLoading(true);
      privateCellularClient
        .getPrivateCellular({
          start: params.startIndex,
          limit: params.maxResults,
          search: search,
          orgId: orgId === 0 ? null : orgId,
        })
        .then(
          ({ data }) => {
            if (table.current) {
              // custom data for working same advanced search
              table.current.updateDataTable({
                data: data.data,
                pageCount: data.count,
                totalItem: { countAll: data.count, countByFilter: data.count },
              });
              table.current.setLoading(false);
            }
          },
          function (err) {
            table.current.setLoading(false);
          }
        );
    }
  };

  const canEdit = () => {
    return (
      features["private_cellular_resolution"] &&
      permissionsMap["PRIVATE_CELLULAR_MANAGE"] &&
      (organisation.organisationType !== "PARTNER" ||
        features["partner_org_manage"])
    );
  };

  const dataOptions = useMemo(
    () => ({
      pageName: pageName,
    }),
    []
  );

  return (
    <>
      <SntDialog
        isShow={isShowDialogMessage}
        onSave={() => {
          setShowDialogMessage(false);
        }}
        saveTxt={language.ok_key}
        isShowCloseButton={false}
        title={language.notifications_key}
      >
        {() => <div>{dialogMessage}</div>}
      </SntDialog>

      <PagePermissionWarning pageName={pageName} />

      <PageHeader
        title={language.UserPermissionCategory_PRIVATE_CELLULAR_title_key}
        tabTitle={language.UserPermissionCategory_PRIVATE_CELLULAR_title_key}
      />

      <SntPanel title={language.configure_private_cellular}>
        <SntInfoPanel>
          {ReactHtmlParser(language.private_cellular_header_key)}
          <ul>
            <li>{language.mac_address_step_1_key}</li>
            <li>{language.private_cellular_step_2_key}</li>
            <li>{language.mac_address_step_3_key}</li>
          </ul>
        </SntInfoPanel>

        <div className="mb-3">{language.add_private_cellular_key}</div>

        {uploadError && (
          <SntWarning>
            <IcomoonIcon
              icon="warning"
              size="18"
              className="me-2"
            ></IcomoonIcon>
            {uploadError}
          </SntWarning>
        )}

        <form>
          {features["private_cellular_resolution"] &&
            permissionsMap["PRIVATE_CELLULAR_MANAGE"] &&
            (organisation.organisationType !== "PARTNER" ||
              features["partner_org_manage"]) && (
              <div className="d-flex">
                <div className="align-self-center">
                  <Button
                    variant="sensolus-greylight"
                    type="button"
                    className="me-2"
                    disabled={!canEdit()}
                    onClick={onUploadClicked}
                  >
                    <span className="fw-bold">{language.upload_excel_key}</span>
                  </Button>
                  <input
                    name="file"
                    type="file"
                    className="form-control"
                    style={{ display: "none" }}
                    disabled={!canEdit()}
                    ref={fileRef}
                  />
                </div>
                <div className="align-self-center">
                  <SntLink
                    onClick={() => privateCellularClient.downloadTemplateFile()}
                  >
                    {language.download_example_excel_key}
                  </SntLink>
                </div>
              </div>
            )}
        </form>
      </SntPanel>

      <AdminLayout
        browserTabTitle={
          language.UserPermissionCategory_PRIVATE_CELLULAR_title_key
        }
        pageName={pageName}
        sharedKey={SHARED_KEY}
        defaultOption={{ filter: { search: "" } }}
        searchPlaceholder={"Search by cell Id"}
        isAdvanceFilter={false}
        onChangeSimpleFilterList={onChangeFilter}
        isShowBackHistory={false}
        body={() => (
          <SntAdvancedSearchTableViewCard
            shared={(child) => (table.current = child)}
            allColumns={allColumns}
            visibleCols={defaultColumnInit}
            onChange={(data) => {
              onDataRequestChange(data);
            }}
            dataOptions={dataOptions}
            key={orgId}
            isDisabbledGroup={true}
          />
        )}
      ></AdminLayout>
    </>
  );
};

export default PrivateCellular;
